.admin_sidebar {
  position: sticky;
  top: 0;
  left: 0;
  width: 330px;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  padding: 25px;
  border-right: 2px solid #eee;
  min-height: 100vh;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  //   rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  @include bezier-transition(all, 0.25s, ease-in-out);
  @include for-tablet-portrait {
    width: 100px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 10px;
  }

  .logo-img {
    width: 200px;
    margin: 0 auto;
    display: flex;
    @include for-tablet-portrait {
      width: 70px;
    }
  }

  .admin_sidebar_content {
    margin: 25px 0;
    .menu_title {
      text-transform: uppercase;
      font-family: "KanitLight";
      font-size: 16px;
      letter-spacing: 1.1px;
      color: #aaa;
      @include for-tablet-portrait {
        text-align: center;
        font-size: 14px;
      }
    }
    .admin_sidebar_menu {
      margin: 15px 10px;
      @include for-tablet-portrait {
        margin: 15px -8px;
      }
      .sidebar_link {
        text-decoration: none;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        svg {
          font-size: 26px;
          color: $primary-dark-gray;
          path {
            stroke: $primary-dark-gray;
          }
        }
        span {
          font-family: "KanitMedium";
          font-size: 18px;
          text-transform: uppercase;
          color: $primary-dark-gray;
          @include for-tablet-portrait {
            display: none;
          }
        }
        &:hover {
          background-color: #fff5eb;
          border-radius: 10px;
          svg {
            color: $primary-color;
            path {
              stroke: $primary-color;
            }
          }
          span {
            color: $primary-color;
          }
        }

        .flexed_div {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
      .adminActiveLink {
        background-color: #fff5eb;
        border-radius: 10px;
        color: $primary-color;
        svg {
          color: $primary-color;
          path {
            stroke: $primary-color;
          }
        }
        span {
          color: $primary-color;
        }
      }
    }
    .logout {
      position: absolute;
      bottom: 20px;
      width: 80%;
      margin: 15px 10px;
      @include for-tablet-portrait {
        margin: 15px -8px;
        width: 70%;
      }
    }
  }
}

.admin_sidebar_none {
  width: 0;
}
