// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../utils/mixins";
@import "../utils/animation";
@import "../utils/colors";

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body-style {
  overflow-y: hidden;
}

body {
  font-family: "KanitRegular", sans-serif !important;
  background: #f5f5f5 !important;
  overflow-x: hidden !important;
  iframe {
    z-index: -2 !important;
  }
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e8e8e8;
    outline: 1px solid #e8e8e8;
  }

  ::-webkit-selection {
    color: #fff;
    background: $primary-color;
  }

  ::-moz-selection {
    color: #fff;
    background: $primary-color;
  }

  ::selection {
    color: #fff;
    background: $primary-color;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0 !important;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-bottom: 0 !important;
}

*:focus {
  outline: none;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
  border-radius: 10px;
}

.css-1pxa9xg-MuiAlert-message {
  font-family: "KanitRegular" !important;
}

.css-13cymwt-control {
  background-color: #f5f5f5 !important;
  border-radius: 50px !important;
  position: relative !important;
  border: 1px solid #e8e8e8 !important;
  width: 100% !important;
  font-size: 15px !important;
  padding: 8px 10px !important;
  box-shadow: none !important;
  font-family: "KanitRegular" !important;
  .css-1jqq78o-placeholder {
    color: #000 !important;
  }
}

.css-t3ipsp-control {
  background-color: #f5f5f5 !important;
  border-radius: 50px !important;
  position: relative !important;
  border: 1px solid #e8e8e8 !important;
  width: 100% !important;
  font-size: 15px !important;
  padding: 8px 10px !important;
  font-family: "KanitRegular" !important;
  box-shadow: none !important;
  .css-1jqq78o-placeholder {
    color: #000 !important;
  }
}

.dublo {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  @include for-phone-only {
    flex-direction: column;
  }
}

.css-13cymwt-control {
  padding: 0px !important;
}

.icon_wrap {
  border-radius: 50px;
  background: rgb(248, 248, 248);
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
  svg {
    font-size: 26px;
    color: $primary-color;
  }
}

.reports {
  grid-gap: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  transition: all 0.2s, color 0.2s;
  // .report-item {
  //   border: 1px solid #eee;
  //   border-radius: 4px;
  //   display: grid;
  //   place-items: center;
  //   background-color: white;
  //   height: 300px;
  //   object-fit: cover;
  //   object-position: top;
  //   transition: all 0.2s, color 0.2s;
  //   width: 100%;
  // }
}
