/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  font-family: "KanitLight";
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 0px;
  border-top: 2px solid #eee;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.4s ease;
  padding: 15px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  // background-color: #fff;
  // color: #252525;
  // padding: 15px;
  border-bottom: 1px solid #eee;
}

/* Style the accordion content title */
.accordion__title {
  font-family: "KanitRegular";
  font-size: 24px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  transform: rotate(90deg);
}

.rotate {
  transition: transform 0.6s ease;
  transform: rotate(270deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.4s ease;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 0px;
  border-top-right-radius: 0px;

  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
  border-top: 0px;
}

/* Style the accordion content text */
.accordion__text {
  font-size: 22px;
  color: #353535;
  padding: 15px;
}
