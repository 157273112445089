.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 20px 40px;
  border-bottom: 2px solid #eee;
  @include for-tablet-portrait {
    padding: 20px;
  }
  .header_left {
    .logo {
      width: 200px;
      @include for-tablet-portrait {
        width: 100px;
      }
    }
  }

  .header_right {
    display: flex;
    align-items: center;
    gap: 10px;

    .profile {
      display: flex;
      align-items: center;
      gap: 10px;
      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
      .user {
        text-align: right;
        line-height: 20px;
        p {
          font-family: "KanitRegular";
        }
        span {
          font-size: 14px;
          color: $primary-color;
        }
      }
      .profile {
        width: 50px;
        height: 50px;
      }
    }
  }
}
