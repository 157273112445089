.main_button {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid $primary-color;
  border-radius: 10px;
  color: #fff;
  z-index: 1;
  background: $primary-color;
  position: relative;
  transition: all 250ms;
  overflow: hidden;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    color: white;
    background-color: #ec942be8;
  }
}

.secondary {
  border: 2px solid #eee;
  background: transparent;
  color: #ec942b;
  &:hover {
    border: 2px solid #eee;
    background: #eee;
    color: #ec942b;
  }
}

.save {
  border: 1px solid $save;
  background: $save;
  &:hover {
    color: white;
    background: $save;
  }
}

.add {
  border: 1px solid $add;
  background: $add;
  &:hover {
    color: $add;
  }
}

.delete {
  border: 1px solid $delete;
  background: $delete;
  &:hover {
    color: $delete;
  }
}

.other {
  border: 1px solid $black;
  background: $black;
  &:hover {
    color: $black;
  }
}

.pink {
  border: 1px solid $pink;
  background: $pink;
  &:hover {
    color: $pink;
  }
}

.purple {
  border: 1px solid $purple;
  background: $purple;
  &:hover {
    color: $purple;
  }
}

.white {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
  &:hover {
    color: #000;
  }
}
