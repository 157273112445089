.branches {
  padding: 10%;
  @include for-tablet-portrait {
    padding: 5%;
  }
  h1 {
    font-family: "KanitRegular";
  }
  .branches_cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
  }
}
.branch_card {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .add_bakery {
    color: #777;
  }
  p {
    font-family: "KanitRegular";
    color: #252525;
    margin-top: 5px;
    font-size: 20px;
  }
}
