.admin_table_header {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  .admin_table_header_items {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 20px;
    p {
      flex: 1;
      text-align: left;
      font-family: "KanitRegular";
      font-size: 18px;
      color: #777;
    }
  }
}

.admin_table_data {
  border-bottom: 2px solid #eee;
  .admin_table_cells {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 20px 10px;
    position: relative;
    p {
      flex: 1;
      text-align: left;
      font-family: "KanitRegular";
      font-size: 16px;
      color: #252525;
    }
    .admin_table_cells_actions {
      display: flex;
      align-items: center;
      gap: 20px;
      position: absolute;
      right: 20px;
      svg {
        cursor: pointer;
      }
    }
  }
}
