$primary-black: #252525;
$primary-green: #4ed249;
$primary-color: #ec942b;
$primary-orange-light: #ffbf75;
$primary-orange-lighter: #fcf2e5;

$primary-dark-gray: #777;
$primary-gray: #eee;
$primary-gray-light: #f3f3f3;
$primary-gray-lighter: #f8f8f8;

$black: #1d152a;
$orange: #ebb271;
$purple: #7f70a0;
$green: #70a080;
$pink: #e29589;
// BUTTONS
$save: #70a080;
$add: #536b9a;
$delete: red;
