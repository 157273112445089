.admin_dashboard {
  display: flex;
  overflow: hidden;
  height: 100vh;
  &_content {
    flex: 1;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    overflow: auto;
    background: #f3f3f3;
    height: 100vh;
    &_component {
      padding: 3%;
    }
  }
}

.admin_dashboard_content {
  &::-webkit-scrollbar {
    display: none;
  }
  &_wrapper {
    padding: 20px;

    .dashboard {
      button {
        width: auto;
      }
      .dashboard_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        padding: 20px;
        border-radius: 10px;
        button {
          width: auto;
        }
      }
    }
  }
}
