.admin_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  .admin_header_title {
    h1 {
      font-family: "KanitMedium";
    }
    p {
      color: $primary-dark-gray;
      font-family: "KanitLight";
      font-size: 20px;
    }
  }

  .admin_header_center {
    margin-right: 40px;
    display: block;
    @include for-laptop-s {
      display: none;
    }
    .admin_header_search_wrapper {
      position: relative;
    }

    .admin_header_search {
      background: white;
      border: 2px solid $primary-gray;
      width: 600px;
      border-radius: 50px;
      font-size: 16px;
      padding: 13px 15px 13px 25px;
      color: $primary-dark-gray;
      font-family: "KanitRegular";
      position: relative;
      color: $primary-black;
      &::placeholder {
        color: $primary-dark-gray;
      }
    }

    .admin_header_search_icon {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 26px;
      transform: translateY(-50%);
      color: #eee;
    }
  }

  .admin_header_actions {
    display: flex;
    align-items: center;
    gap: 15;
  }
}

.admin_header_mobile {
  display: none;
  @include for-laptop-s {
    display: block;
    padding-bottom: 20px;
  }
  .admin_header_search_wrapper {
    position: relative;
  }

  .admin_header_search {
    background: white;
    border: 2px solid $primary-gray;
    width: 100%;
    border-radius: 50px;
    font-size: 16px;
    padding: 13px 15px 13px 25px;
    color: $primary-dark-gray;
    font-family: "KanitRegular";
    position: relative;
    color: $primary-black;
    &::placeholder {
      color: $primary-dark-gray;
    }
  }

  .admin_header_search_icon {
    position: absolute;
    top: 50%;
    right: 20px;
    font-size: 26px;
    transform: translateY(-50%);
    color: #eee;
  }
}
