.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  font-family: "KanitRegular" !important;
  background-color: white;
  padding: 24px !important;
  width: 650px !important;
  min-height: 200px !important;
  border-radius: 16px !important;
  overflow-y: hidden !important;
  // overflow-y: scroll !important;
  @include for-phone-only {
    margin: 10px !important;
    padding: 5px !important;
  }
  .dialog_header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    h4 {
      margin-bottom: 0px !important;
    }
    .close_icon {
      font-size: 25px;
      cursor: pointer;
    }
  }

  select {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    border: 1px solid #e8e8e8;
    color: #000;
    width: 100%;
    font-size: 15px;
    padding: 13px 15px 13px 15px;
    font-family: "KanitRegular";
  }
}

.css-uhb5lp {
  font-family: "KanitRegular" !important;
  background-color: white !important;
  padding: 24px !important;
  width: 650px !important;
  height: auto !important;
  border-radius: 16px !important;
  overflow-y: hidden !important;
  // overflow-y: scroll !important;
  @include for-phone-only {
    margin: 10px !important;
    padding: 5px !important;
  }
  .dialog_header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    h4 {
      margin-bottom: 0px !important;
    }
    .close_icon {
      font-size: 25px;
      cursor: pointer;
    }
  }

  select {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    border: 1px solid #e8e8e8;
    color: #000;
    width: 100%;
    font-size: 15px;
    padding: 13px 15px 13px 15px;
    font-family: "KanitRegular";
  }
}

.css-ypiqx9-MuiDialogContent-root {
  overflow: scroll !important;
  padding: 0px 15px !important;
  &::-webkit-scrollbar {
    width: 0.3em !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e8e8e8 !important;
    outline: 1px solid #e8e8e8 !important;
  }
}
.css-1ty026z {
  overflow: scroll !important;
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e8e8e8 !important ;
    outline: 1px solid #e8e8e8 !important;
  }
}
