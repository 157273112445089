.select_custom {
  display: flex;
  flex-direction: column;
  width: 100%;
  select {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    border: 1px solid #e8e8e8;
    color: #000;
    width: 100%;
    font-size: 15px;
    padding: 13px 15px 13px 15px;
  }
}
