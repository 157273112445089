.dashboard_navigation {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 9;
  padding: 20px;
  border-bottom: 2px solid #eee;
}
.sidebar_toggle {
  color: $primary-black;
  font-size: 40px;
  cursor: pointer;
  margin-top: 10px;
}
.dashboard_navigation_right {
  display: flex;
  align-items: center;
  gap: 30px;
  .user {
    display: flex;
    align-items: center;
    gap: 10px;
    .user_on {
      text-align: right;
      line-height: 20px;
      display: block;
      @include for-tablet-portrait {
        display: none;
      }
      p {
        font-family: "KanitRegular";
      }
      span {
        font-size: 14px;
        color: $primary-color;
      }
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}
