.input_custom {
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    font-size: 18px;
  }
  input {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    border: 1px solid #e8e8e8;
    color: $primary-black;
    width: 100%;
    font-size: 16px;
    padding: 13px 15px 13px 15px;
    font-family: "KanitLight";
    &::placeholder {
      color: $primary-dark-gray;
    }
  }
}
