// .loader {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   img {
//     width: 300px;
//     animation: grow-and-shrink 1s ease-in-out infinite;
//   }
// }
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 100vh;
  .loader {
    border: 5px solid $primary-color;
    border-left-color: transparent;
    border-radius: 50%;
  }

  .loader {
    border: 5px solid $primary-color;
    border-left-color: transparent;
    width: 50px;
    height: 50px;
  }

  .loader {
    border: 5px solid $primary-color;
    border-left-color: transparent;
    width: 50px;
    height: 50px;
    animation: spin89345 1s linear infinite;
  }

  @keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
