.sales_revenue {
  margin-top: 20px;
  .sales_revenue_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &_left {
      display: flex;
      align-items: center;
      gap: 1rem;

      h2 {
        display: block;
        @include for-phone-only {
          display: none;
        }
      }
    }
    .react-tabs__tab {
      bottom: 0px !important;
    }
    .react-tabs__tab-list {
      position: absolute;
      right: 20px;
      background-color: #fcf2e5;
      border: none !important;
      display: flex;
      margin: 0 auto;
      border-radius: 10px;
      padding: 7px;
    }
    .react-tabs__tab {
      padding: 5px 15px;
      font-size: 16px;
      cursor: pointer;
      font-family: "KanitLight";
    }
    .react-tabs__tab--selected {
      border: none;
      color: #fff;
      border-radius: 5px;
      background-color: $primary-color !important;
      font-family: "KanitRegular";
    }
  }
  .sales_revenue {
    padding: 20px;
  }
}
