.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $primary-orange-lighter;
  .login_content {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    padding: 3% 2%;
    transition: all 0.5s ease 0s;
    width: 550px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    @include for-tablet-portrait {
      width: 90%;
      padding: 10% 5%;
    }
    .logo {
      width: 300px;
      display: flex;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    .login_content_actions {
      margin-top: 30px;
    }
  }
}
